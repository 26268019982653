import type { ComponentType } from "react"
import { useEffect, useState } from "react"

const CAPTION_SIZE_FACTOR = 0.85

export const withCaptionAlignCenter = (
    Component: ComponentType
): ComponentType => {
    return (props: any) => {
        const [originalFontSize, setOriginalFontSize] = useState(16)

        useEffect(() => {
            const images = document.querySelectorAll(
                `.${props.className} .framer-image`
            )

            images.forEach((imageElement) => {
                const nextElement = imageElement.nextElementSibling

                if (
                    nextElement &&
                    nextElement.tagName &&
                    nextElement.tagName.toLowerCase() === "p"
                ) {
                    nextElement.classList.add("caption")
                    setOriginalFontSize(
                        parseFloat(
                            window.getComputedStyle(nextElement).fontSize
                        )
                    )
                }
            })
        }, [props.className])

        return (
            <>
                <style>{`.${props.className} img {
                     margin: 0 auto !important;                    
                }
                .${props.className} .caption {
                    text-align: center !important;
                    font-size: ${
                        originalFontSize * CAPTION_SIZE_FACTOR
                    }px !important;
                    margin-top: 0 !important;
                    padding-top: 0 !important;
                }`}</style>
                <Component {...props} />
            </>
        )
    }
}
